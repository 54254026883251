.root {
  text-align: center;
}

.container {
  width: 85%;
  margin: -100px auto 20px auto;
  border-radius: 40px;
  background-color: #4AA79E;
  position: relative;
  border: 7px solid #ffffff;
  overflow: hidden;
  z-index: 1;
  text-align: center;
}

.bgImg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.linkPanel {
  text-align: left;
  margin: 100px auto 100px auto;
  width: 85%;
}

.communityContainer {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.title {
  opacity: 0.5;
  padding-top: 100px;
  letter-spacing: -0.01em;
}

.stayText {
  font-family: 'Spartan';
  position: relative;
  width: fit-content;
  margin: 25px auto;
  letter-spacing: -0.01em;
}

.underlineCurve {
  position: absolute;
  left: 0;
  bottom: -10px;
  max-width: 120%;
}

.guide {
  color: #D3D5DA;
  font-weight: 500;
  letter-spacing: -0.05em;
}

.inputField {
  background-color: #ffffff;
  width: 80%;
  margin-top: 35px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  padding: 10px;
  text-align: left;
  align-items: row;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.emailInput {
  border: none;
  color: #000000;
  background-color: #ffffff;
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  letter-spacing: -0.045em;
  opacity: 0.5;
  font-size: 18px;
}

.submitButton {
  color: #ffffff;
  width: 30%;
  font-size: 16px;
  padding: 15px;
  border-radius: 10px;
  margin-top: auto;
  margin-bottom: auto;
  background-color: #1E266D;
  text-transform: none;
  font-family: 'Spartan';
  font-weight: 800;
}

.joinSentence {
  color: #FCFCFC;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 500;
}

.description {
  color: #ECECEC;
  padding-top: 20px;
  line-height: 25px;
  font-weight: 400;
  max-width: 330px;
}

.groupName {
  padding-bottom: 20px;
  font-weight: 400;
}

.link {
  color: #DFE0EB;
  font-weight: 600;
  margin-top: 20px;
}

.socialIcon {
  margin-right: 20px;
}

.bottomFooter {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-top: 150px;
}

.copyRight {
  color: #A4FBF3;
  position: absolute;
  left: 0;
  font-size: 12.6px;
  font-weight: 400;
  font-family: 'Spartan';
}

.manageSettings {
  color: #DFE0EB;
  position: absolute;
  right: 10px;
  font-size: 12.6px;
  font-weight: 600;
  font-family: 'Spartan';
}

@media (max-width: 1024px) {
  .container {
    width: 95%;
    border-radius: 20px;
    border: 3px solid #ffffff;
    padding: 5px;
  }

  .linkPanel {
    margin: 50px auto 50px auto;
    width: 100%;
    padding: 5px;
  }

  .title {
    padding-top: 30px;
  }

  .inputField {
    margin-top: 20px;
    border-radius: 7px;
    padding: 5px;
  }

  .submitButton {
    width: 30%;
    font-size: 14px;
    padding: 10px;
    border-radius: 7px;
  }

  .bottomFooter {
    padding-top: 50px;
  }

  .description {
    line-height: 20px;
    max-width: 95%;
  }
}

@media (max-width: 448px) {
  .emailInput {
    margin-left: 5px;
    font-size: 14px;
  }

  .inputField {
    min-width: 250px;
  }
}