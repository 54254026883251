.root {}

.safeList {
  max-width: 700px;
  margin: 100px auto 100px auto;
}

.swapping {
  max-width: 700px;
  margin: 100px auto 100px auto;
}

.swappingTitle {
  color: #FFFFFF;
  text-align: center;
  padding-bottom: 50px;
}

.tr {}

.th {}

.td {}

.swapButton {
  text-transform: none;
  font-size: 18px;
}