.root {
  padding-top: 50px;
}

.topButtonGroup {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 60px;
}

.topButton {
  color: #FFFFFF;
  text-transform: none;
  margin-left: 8px;
  margin-right: 8px;
  padding: 12px 36px 12px 36px;
  font-family: 'Spartan';
  font-weight: 600;
  font-size: 14px;
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .root {
    padding-top: 15px;
  }

  .topButtonGroup {
    margin-top: 15px;
  }
}

@media (max-width: 448px) {
  .topButton {
    margin-left: 4px;
    margin-right: 4px;
    padding: 8px;
    font-size: 12px;
    border-radius: 6px;
  }
}