.root {
  color: #ffffff;
  margin-bottom: 200px;
}

.sectionTitle {
  text-align: center;
}

.tr {
}

.selectedTr {
  background-color: grey;
}

.th {
  
}

.td {
  padding: 5px;
}

.configBox {
  margin-left: 2%;
  margin-right: 2%;
}

.safesBox {
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 80px;
}
