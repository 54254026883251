.root {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.summaryBox {
  background: #393953;
  border-radius: 16px;
  padding: 24px 24px 60px 24px;
}

.summaryBoxHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iconBox {
  border-radius: 50%;
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.safeTitle {
  color: #FFFFFF;
  font-family: 'Manrope';
  font-weight: 700;
  letter-spacing: 0.001em !important;
  text-indent: 1em;
  flex: 1;
}

.redIcon {
  margin-left: 15px;
}

.description {
  color: #EAEAED;
  font-family: 'Manrope';
  font-weight: 500;
  margin-top: 10px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.key {
  color: #C8C7D8;
  font-family: 'Space Mono';
  font-weight: 400;
}

.value {
  color: #C8C7D8;
  font-family: 'Space Mono';
  font-weight: 400;
  width: 8em;
}

@media (max-width: 512px) {
  .root {
    width: 90%;
  }

  .summaryBox {
    border-radius: 12px;
    padding: 18px 18px 40px 18px;
  }

  .iconBox {
    width: 40px;
    height: 40px;
  }

  .tokenIcon {
    width: 60%;
  }
}
