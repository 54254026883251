.root {
  border-radius: 16px;
  background-color: #393953;
  width: 90%;
  margin: 18px auto 72px auto;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
}

.container {
  padding: 60px 2% 30px 5%;
}

.descriptionBox {}

.tokenName {
  font-family: 'Spartan';
  font-weight: 700;
  color: #4DB2A8;
  line-height: 200%;
}

.descriptionText {
  font-family: 'Spartan';
  font-weight: 400;
  color: #DBDBDB;
  opacity: 0.8;
  line-height: 200%;
}

.appBox {
  background-color: #505076;
  border-radius: 16px;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  height: 100%;
  padding-left: 8%;
  padding-right: 8%;
  display: flex;
  flex-direction: column;
}

.buttonGroup {
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
  background-color: #393953;
  border-radius: 16px;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  padding: 5px 5% 5px 5%;
}

.buttonAppSelect {
  font-family: 'Spartan';
  text-transform: none;
  font-size: 14px;
  letter-spacing: 0.02em;
  padding: 12px 0px 12px 0px;
  border-radius: 10px;
  flex: 1;
}

.buttonActive {
  font-weight: 700;
  color: #FFFFFF;
}

.buttonInactive {
  font-weight: 500;
  color: #E7E7FF;
}

.boxApp {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
}

.boxClaim {}

.boxProtect {}

.boxWithdraw {}

.paramArea {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: right;
  gap: 15px;
}

.feeBox {
}

.paramName {
  font-family: 'Spartan';
  font-weight: 400;
  color: #FFFFFF;
  margin-bottom: 8px;
}

.paramValue {
  font-family: 'Spartan';
  font-weight: 500;
  color: #9D9D9D;
  background-color: #4E4E5F;
  border: 1px solid #2A2A40;
  border-radius: 10px;
  padding: 10px;
  width: 100px;
  margin-right: 0;
  margin-left: auto;
}

.delayList {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  align-content: flex-end;
}

.delayValue {
  font-family: 'Spartan';
  font-weight: 500;
  color: #FFFFFF;
  border-radius: 10px;
  background-color: #3B3B59;
  border: 1px solid #2A2A40;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 65px;
  text-align: center;
  margin-right: 5px;
}

.delayValueSelected {
  background-color: #5E5E83;
  border-color: #4DB2A8;
}

.tailTag {
  font-size: 10px;
  font-family: 'Spartan';
  font-weight: 700;
  color: #FFFFFF;
  padding-top: 5px;
}

.pendingAssets {
  color: #FFFFFF;
}

.pendingContainer {
}

.pendingBox {
  padding: 20px;
}

.pendingTitle {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.th {
  color: #DDDDDD;
}

@media (max-width: 1024px) {
  .appBox {
    height: 400px;
  }

  .boxApp {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (max-width: 768px) {}

@media (max-width: 448px) {
  .appBox {
    border-radius: 12px;
    padding-left: 2%;
    padding-right: 2%;
  }

  .buttonGroup {
    margin-top: 20px;
    border-radius: 12px;
    padding: 5px 0 5px 0;
  }

  .buttonAppSelect {
    font-size: 12px;
    border-radius: 6px;
    padding: 8px 0px 8px 0px;
  }

  .delayValue {
    width: 60px;
  }

  .pendingBox {
    padding: 10px;
  }

  .pendingTitle {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}