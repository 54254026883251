.root {
  border-radius: 16px;
  background-color: #393953;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  position: relative;
}

.backButton {
  width: 120px;
  height: 50px;
  position: absolute;
  top: -25px;
  left: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #393953;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 16px;
}

.backIcon {
  margin-right: 10px;
  height: 80%;
}

.backText {
  color: #8989A0;
  font-family: 'Spartan';
  font-weight: 500;
}

.container {
  padding: 50px 5%;
  align-items: center;
}

.tokenBox {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.nameBox {
  display: flex;
  align-items: flex-end;
  margin-bottom: 50px;
}

.tokenSymbol {
  font-family: 'Spartan';
  font-weight: 600;
  color: #FFFFFF;
}

.tokenName {
  font-family: 'Spartan';
  font-weight: 600;
  color: #FFFFFF;
  opacity: 0.5;
  text-indent: 0.5em;
}

.percentLineParent {
  height: 4px;
  width: 100%;
  border-radius: 2px;
  background-color: #FFFFFF;
}

.percentLineChild {
  height: 100%;
  border-radius: 2px;
  background-color: #4DB2A8;
}

.percentDesciption {
  font-family: 'Spartan';
  font-weight: 600;
  color: #FFFFFF;
  font-size: 10px;
  padding-top: 10px;
}

.text1 {
  font-family: 'Spartan';
  font-weight: 600;
  color: #FFFFFF;
  line-height: 200%;
}

.text2 {
  font-family: 'Spartan';
  font-weight: 400;
  color: #FFFFFF;
  opacity: 0.5;
  line-height: 200%;
}

@media (max-width: 1024px) {
  .nameBox {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 30px 5%;
  }
}

@media (max-width: 448px) {
  .backButton {
    width: 70px;
    height: 25px;
    top: -12px;
    left: 25px;
    border-radius: 8px;
  }

  .backIcon {
    margin-right: 5px;
  }
}