.root {
  position: relative;
  background-color: #2B2B41;
  border-radius: 10px;
  align-items: center;
  display: flex;
  padding: 6px 12px;
}

.tagAmount {
  color: #FFFFFF;
  font-family: 'Spartan';
  font-weight: 400;
  position: absolute;
  top: -12px;
  left: 20px;
  background-color: #505076;
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
}

.tagMax {
  color: #FF004C;
  font-family: 'Spartan';
  font-weight: 700;
  font-size: 12px;
  background-color: #FF004C21;
  padding: 5px;
  border-radius: 24px;
}

.input {
  flex: 1;
  border: none;
  margin-right: 10px;
}

.tokenButton {
  font-family: 'Spartan';
  font-weight: 700;
  font-size: 12px;
  padding: 8px;
  width: 20%;
  border-radius: 9px;
}

@media (max-width: 448px) {
  .root {
    padding: 4px;
  }
  
  .tagAmount {
    top: -8px;
    left: 12px;
  }
}