.root {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin: 150px auto 50px auto;
  width: 70%;
  padding: 45px 5%;
  background: linear-gradient(325.79deg, #4BAAA1 24.5%, #33766F 117.69%);
  text-align: center;
}

.title {
  font-weight: 400;
  letter-spacing: -0.014em;
}

.question {
  letter-spacing: -0.022em;
  margin-top: 10px;
}

.button {
  font-size: 16px;
  font-family: 'Spartan';
  color: #4DB2A8;
  background-color: #ffffff;
  text-transform: none;
  margin-top: 40px;
  padding: 10px;
  border-radius: 8px;
}

.button:hover {
  background-color: #eeeeee !important;
}

@media (max-width: 1024px) {
  .root {
    width: 80%;
    padding: 30px 5%;
    margin-top: 75px;
  }

  .button {
    font-size: 14px;
    margin-top: 30px;
    padding: 5px;
  }
}
