.root {
  text-transform: none;
  font-family: 'Spartan';
  font-size: 16px;
  padding: 12px 16px 12px 16px;
  border-radius: 7px;
}

@media (max-width: 448px) {
  .root {
    font-size: 14px;
    padding: 6px;
    border-radius: 4px;
  }
}