@import url(https://fonts.googleapis.com/css2?family=Space+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Spartan&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Spinnaker&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Stoke&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2C3242;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  background-image: url(/static/media/BGCurveGroup.5bf0927b.svg);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.Body_root__2n5ng {
  padding-top: 100px;
}

.WalletConnectButton_root__Q1FpT {
  text-transform: none;
  font-family: 'Spartan';
  font-size: 16px;
  padding: 12px 16px 12px 16px;
  border-radius: 7px;
}

@media (max-width: 448px) {
  .WalletConnectButton_root__Q1FpT {
    font-size: 14px;
    padding: 6px;
    border-radius: 4px;
  }
}
.Header_root__3fS4f {
  justify-content: space-between;
  padding: 20px 0px 20px 30px;
  position: fixed;
  background-color: #2C3242;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.Header_shortButton__2REZr {
  text-transform: none;
  font-family: 'Space Mono';
  font-size: 16px;
  padding: 12px 16px 12px 16px;
  margin-right: 10px;
}

.Header_leftGroup__1bJ_y {
  align-items: center;
  display: flex;
}

.Header_rightGroup__2lqlg {
  display: flex;
  margin-right: 60px;
}

.Header_buttonGroup__sjBeC {
  display: none;
}

@media (max-width: 1024px) {
  .Header_root__3fS4f {
    padding: 10px 0px 10px 10px;
  }
  .Header_rightGroup__2lqlg {
    margin-right: 20px;
  }
}

@media (max-width: 768px) {
  .Header_buttonGroup__sjBeC {
    display: none;
  }
}

@media (max-width: 448px) {
  .Header_leftGroup__1bJ_y {
    max-width: 30%;
  }

  .Header_logo__1vA-q {
    width: 100%;
  }
}

.Footer_root___g71L {
  text-align: center;
}

.Footer_container__pIg0D {
  width: 85%;
  margin: -100px auto 20px auto;
  border-radius: 40px;
  background-color: #4AA79E;
  position: relative;
  border: 7px solid #ffffff;
  overflow: hidden;
  z-index: 1;
  text-align: center;
}

.Footer_bgImg__2hLPS {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.Footer_linkPanel__20mTx {
  text-align: left;
  margin: 100px auto 100px auto;
  width: 85%;
}

.Footer_communityContainer__Apf4p {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.Footer_title__2R26t {
  opacity: 0.5;
  padding-top: 100px;
  letter-spacing: -0.01em;
}

.Footer_stayText__NGPiX {
  font-family: 'Spartan';
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 25px auto;
  letter-spacing: -0.01em;
}

.Footer_underlineCurve__2Vxqh {
  position: absolute;
  left: 0;
  bottom: -10px;
  max-width: 120%;
}

.Footer_guide__3cWBi {
  color: #D3D5DA;
  font-weight: 500;
  letter-spacing: -0.05em;
}

.Footer_inputField__3K5DT {
  background-color: #ffffff;
  width: 80%;
  margin-top: 35px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  padding: 10px;
  text-align: left;
  align-items: row;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Footer_emailInput__3V-w5 {
  border: none;
  color: #000000;
  background-color: #ffffff;
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  letter-spacing: -0.045em;
  opacity: 0.5;
  font-size: 18px;
}

.Footer_submitButton__FJ_wL {
  color: #ffffff;
  width: 30%;
  font-size: 16px;
  padding: 15px;
  border-radius: 10px;
  margin-top: auto;
  margin-bottom: auto;
  background-color: #1E266D;
  text-transform: none;
  font-family: 'Spartan';
  font-weight: 800;
}

.Footer_joinSentence__36LgU {
  color: #FCFCFC;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 500;
}

.Footer_description__134JU {
  color: #ECECEC;
  padding-top: 20px;
  line-height: 25px;
  font-weight: 400;
  max-width: 330px;
}

.Footer_groupName__vHIH8 {
  padding-bottom: 20px;
  font-weight: 400;
}

.Footer_link__21CqK {
  color: #DFE0EB;
  font-weight: 600;
  margin-top: 20px;
}

.Footer_socialIcon__1OgR2 {
  margin-right: 20px;
}

.Footer_bottomFooter__37WMj {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-top: 150px;
}

.Footer_copyRight__PhlOX {
  color: #A4FBF3;
  position: absolute;
  left: 0;
  font-size: 12.6px;
  font-weight: 400;
  font-family: 'Spartan';
}

.Footer_manageSettings__2kT8o {
  color: #DFE0EB;
  position: absolute;
  right: 10px;
  font-size: 12.6px;
  font-weight: 600;
  font-family: 'Spartan';
}

@media (max-width: 1024px) {
  .Footer_container__pIg0D {
    width: 95%;
    border-radius: 20px;
    border: 3px solid #ffffff;
    padding: 5px;
  }

  .Footer_linkPanel__20mTx {
    margin: 50px auto 50px auto;
    width: 100%;
    padding: 5px;
  }

  .Footer_title__2R26t {
    padding-top: 30px;
  }

  .Footer_inputField__3K5DT {
    margin-top: 20px;
    border-radius: 7px;
    padding: 5px;
  }

  .Footer_submitButton__FJ_wL {
    width: 30%;
    font-size: 14px;
    padding: 10px;
    border-radius: 7px;
  }

  .Footer_bottomFooter__37WMj {
    padding-top: 50px;
  }

  .Footer_description__134JU {
    line-height: 20px;
    max-width: 95%;
  }
}

@media (max-width: 448px) {
  .Footer_emailInput__3V-w5 {
    margin-left: 5px;
    font-size: 14px;
  }

  .Footer_inputField__3K5DT {
    min-width: 250px;
  }
}
.SectionLearn_root__3jG4x {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 40px;
  height: 550px;
  position: relative;
  background-color: #3D4559;
  overflow: hidden;
}

.SectionLearn_bgImg__2BUDt {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.SectionLearn_title__16fCr {
  opacity: 0.5;
  padding-top: 75px;
  letter-spacing: -0.01em;
}

.SectionLearn_header__396CP {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 25px auto;
}

.SectionLearn_headerText__WIqpA {
  letter-spacing: -0.01em;
}

.SectionLearn_headerCurve__2effa {
  position: absolute;
  right: 0;
  bottom: -10px;
  max-width: 80%;
}

.SectionLearn_description__1d7fd {
  color: #D3D5DA;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 500;
  letter-spacing: -0.05em;
  margin-bottom: 20px;
}

.SectionLearn_learnButton__2-AqP {
  background-color: #ffffff;
  color: #1E266D;
  text-transform: none;
  font-family: 'Spartan';
  width: 200px;
  height: 80px;
  border-radius: 15px;
  font-weight: 700;
  margin: 10px;
  font-size: 16px;
  padding: 10px;
}

@media (max-width: 1024px) {
  .SectionLearn_root__3jG4x {
    width: 90%;
    border-radius: 30px;
    height: 500px;
  }
}

@media (max-width: 768px) {
  .SectionLearn_root__3jG4x {
    width: 90%;
    border-radius: 30px;
    height: 450px;
  }

  .SectionLearn_learnButton__2-AqP {
    width: 150px;
    margin: 5px;
    height: 60px;
    font-size: 14px;
  }
}

@media (max-width: 448px) {
  .SectionLearn_root__3jG4x {
    border-radius: 20px;
  }

  .SectionLearn_learnButton__2-AqP {
    width: 110px;
    height: 40px;
    border-radius: 8px;
    font-size: 12px;
    letter-spacing: -0.1em;
  }
}
.Admin_root__2qEQc {
  color: #ffffff;
  margin-bottom: 200px;
}

.Admin_sectionTitle__29WzM {
  text-align: center;
}

.Admin_tr__3qDY2 {
}

.Admin_selectedTr__i3HwU {
  background-color: grey;
}

.Admin_th__1PrsO {
  
}

.Admin_td__3Cigd {
  padding: 5px;
}

.Admin_configBox__3mbfx {
  margin-left: 2%;
  margin-right: 2%;
}

.Admin_safesBox__2tOKr {
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 80px;
}

.Safes_root__21m2I {

}

.SectionAddVault_root__1sOkY {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin: 150px auto 50px auto;
  width: 70%;
  padding: 45px 5%;
  background: linear-gradient(325.79deg, #4BAAA1 24.5%, #33766F 117.69%);
  text-align: center;
}

.SectionAddVault_title__AVEeE {
  font-weight: 400;
  letter-spacing: -0.014em;
}

.SectionAddVault_question__3CB1y {
  letter-spacing: -0.022em;
  margin-top: 10px;
}

.SectionAddVault_button__3-quW {
  font-size: 16px;
  font-family: 'Spartan';
  color: #4DB2A8;
  background-color: #ffffff;
  text-transform: none;
  margin-top: 40px;
  padding: 10px;
  border-radius: 8px;
}

.SectionAddVault_button__3-quW:hover {
  background-color: #eeeeee !important;
}

@media (max-width: 1024px) {
  .SectionAddVault_root__1sOkY {
    width: 80%;
    padding: 30px 5%;
    margin-top: 75px;
  }

  .SectionAddVault_button__3-quW {
    font-size: 14px;
    margin-top: 30px;
    padding: 5px;
  }
}

.SectionOverview_root__yWxCq {
  background: #393953;
  border-radius: 16px;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 100px;
  padding-bottom: 30px;
  padding-top: 100px;
}

.SectionOverview_topStates__1EwC6 {
  position: absolute;
  top: -75px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.SectionOverview_topState__25162 {
  background-color: #393953;
  box-shadow: 14px 22px 52px -4px rgba(127, 127, 127, 0.13);
  border-radius: 40px;
  margin-left: 8px;
  margin-right: 8px;
  padding: 32px;
  display: flex;
}

.SectionOverview_topStateValue__15vv8 {
  color: #FFFFFF;
  font-family: 'Spartan';
  font-weight: 700;
}

.SectionOverview_topStateTitle__jNZTJ {
  color: #FFFFFF;
  font-family: 'Spartan';
  font-weight: 400;
}

.SectionOverview_leftColorPanel__3tZTS {
  position: relative;
  width: 65px;
  height: 65px;
  border-radius: 24px;
}

.SectionOverview_leftColorPanel1__taC5a {
  background: linear-gradient(154.49deg, #FFE8AC 6.61%, #FFB700 89.72%);
  box-shadow: 0px 10px 22px -2px rgba(255, 183, 0, 0.3);
}

.SectionOverview_leftColorPanel2__IgdTe {
  background: linear-gradient(154.49deg, #313555 6.61%, #171A31 89.72%);
  box-shadow: 0px 10px 22px -2px rgba(23, 26, 49, 0.3);
}

.SectionOverview_subtractIcon1__1va-t {
  position: absolute;
  left: 2px;
  top: -5px;
}

.SectionOverview_subtractIcon2__oej4j {
  position: absolute;
  left: -15px;
  top: -5px;
}

.SectionOverview_rightTextPanel__BRfV1 {
  margin-left: 30px;
  height: 100%;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
}

.SectionOverview_title__roMFY {
  color: #FFFFFF;
  font-family: 'Spartan';
  font-weight: 700;
  text-align: center;
}

.SectionOverview_searchBox__3LxU_ {
  margin: 40px auto 40px auto;
  width: 95%;
  background: rgba(253, 253, 253, 0.88);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  border-radius: 14px;
  padding-top: 18px;
  padding-bottom: 18px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.SectionOverview_searchIcon__tAep8 {
  margin-right: 10px;
}

.SectionOverview_searchText__3bz9f {
  color: #1E266D;
  font-family: 'Manrope';
  font-weight: 400;
  opacity: 0.7;
}

.SectionOverview_buttonGroup__1D1RR {
  align-items: left;
  display: flex;
  margin-left: 30px;
}

.SectionOverview_button__3oHM_ {
  box-shadow: 14px 22px 52px -12px rgba(127, 127, 127, 0.13);
  border-radius: 10px;
  text-transform: none;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 16px;
  padding: 16px 50px 16px 20px;
  margin-right: 10px;
}

.SectionOverview_buttonAllNetworks__2V18e {
  background: #6966FF;
  color: #F7F9FB;
}

.SectionOverview_buttonAllTypes__M1o7x {
  background: #F7F9FB;
  color: #171A31;
}

@media (max-width: 768px) {
  .SectionOverview_root__yWxCq {
    width: 95%;
    padding-top: 50px;
  }

  .SectionOverview_topStates__1EwC6 {
    top: -40px;
  }

  .SectionOverview_topState__25162 {
    max-width: 250px;
    padding: 10px;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 20px;
  }

  .SectionOverview_leftColorPanel__3tZTS {
    width: 40px;
    height: 40px;
    border-radius: 12px;
  }

  .SectionOverview_subtractIcon1__1va-t {
    width: 15px;
    left: 1px;
    top: -3px;
  }
  
  .SectionOverview_subtractIcon2__oej4j {
    width: 15px;
    left: -8px;
    top: -3px;
  }

  .SectionOverview_rightTextPanel__BRfV1 {
    margin-left: 10px;
  }

  .SectionOverview_searchBox__3LxU_ {
    margin: 20px auto 20px auto;
    border-radius: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .SectionOverview_searchIcon__tAep8 {
    width: 15px;
  }

  .SectionOverview_buttonGroup__1D1RR {
    margin-left: 10px;
  }

  .SectionOverview_button__3oHM_ {
    padding: 8px;
    border-radius: 5px;
    font-size: 14px;
  }
}
.SectionSafeList_root__10nF9 {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.SectionSafeList_summaryBox__3rLYR {
  background: #393953;
  border-radius: 16px;
  padding: 24px 24px 60px 24px;
}

.SectionSafeList_summaryBoxHeader__3GawM {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SectionSafeList_iconBox__1ldpW {
  border-radius: 50%;
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SectionSafeList_safeTitle__27q81 {
  color: #FFFFFF;
  font-family: 'Manrope';
  font-weight: 700;
  letter-spacing: 0.001em !important;
  text-indent: 1em;
  flex: 1 1;
}

.SectionSafeList_redIcon__1T3EG {
  margin-left: 15px;
}

.SectionSafeList_description__1DoCy {
  color: #EAEAED;
  font-family: 'Manrope';
  font-weight: 500;
  margin-top: 10px;
}

.SectionSafeList_row__3nTVK {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.SectionSafeList_key__1_cVe {
  color: #C8C7D8;
  font-family: 'Space Mono';
  font-weight: 400;
}

.SectionSafeList_value__n_ibI {
  color: #C8C7D8;
  font-family: 'Space Mono';
  font-weight: 400;
  width: 8em;
}

@media (max-width: 512px) {
  .SectionSafeList_root__10nF9 {
    width: 90%;
  }

  .SectionSafeList_summaryBox__3rLYR {
    border-radius: 12px;
    padding: 18px 18px 40px 18px;
  }

  .SectionSafeList_iconBox__1ldpW {
    width: 40px;
    height: 40px;
  }

  .SectionSafeList_tokenIcon__WYq7L {
    width: 60%;
  }
}

.TokenProperty_descriptionText__2-nB3 {
  font-family: 'Spartan';
  font-weight: 400;
  color: #DBDBDB;
  opacity: 0.8;
  line-height: 200%;
}
.SafeHeader_root__1SBBe {
  border-radius: 16px;
  background-color: #393953;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  position: relative;
}

.SafeHeader_backButton__1sfWj {
  width: 120px;
  height: 50px;
  position: absolute;
  top: -25px;
  left: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #393953;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 16px;
}

.SafeHeader_backIcon__2-RX6 {
  margin-right: 10px;
  height: 80%;
}

.SafeHeader_backText__6wCSU {
  color: #8989A0;
  font-family: 'Spartan';
  font-weight: 500;
}

.SafeHeader_container__218A_ {
  padding: 50px 5%;
  align-items: center;
}

.SafeHeader_tokenBox__1eHaB {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.SafeHeader_nameBox__2JTNc {
  display: flex;
  align-items: flex-end;
  margin-bottom: 50px;
}

.SafeHeader_tokenSymbol__3BxQb {
  font-family: 'Spartan';
  font-weight: 600;
  color: #FFFFFF;
}

.SafeHeader_tokenName__HBuCt {
  font-family: 'Spartan';
  font-weight: 600;
  color: #FFFFFF;
  opacity: 0.5;
  text-indent: 0.5em;
}

.SafeHeader_percentLineParent__B9d9V {
  height: 4px;
  width: 100%;
  border-radius: 2px;
  background-color: #FFFFFF;
}

.SafeHeader_percentLineChild__3c9GW {
  height: 100%;
  border-radius: 2px;
  background-color: #4DB2A8;
}

.SafeHeader_percentDesciption__2M_jl {
  font-family: 'Spartan';
  font-weight: 600;
  color: #FFFFFF;
  font-size: 10px;
  padding-top: 10px;
}

.SafeHeader_text1__CE0ut {
  font-family: 'Spartan';
  font-weight: 600;
  color: #FFFFFF;
  line-height: 200%;
}

.SafeHeader_text2__x2TiK {
  font-family: 'Spartan';
  font-weight: 400;
  color: #FFFFFF;
  opacity: 0.5;
  line-height: 200%;
}

@media (max-width: 1024px) {
  .SafeHeader_nameBox__2JTNc {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .SafeHeader_container__218A_ {
    padding: 30px 5%;
  }
}

@media (max-width: 448px) {
  .SafeHeader_backButton__1sfWj {
    width: 70px;
    height: 25px;
    top: -12px;
    left: 25px;
    border-radius: 8px;
  }

  .SafeHeader_backIcon__2-RX6 {
    margin-right: 5px;
  }
}
.AmountInput_root__2_FMn {
  position: relative;
  background-color: #2B2B41;
  border-radius: 10px;
  align-items: center;
  display: flex;
  padding: 6px 12px;
}

.AmountInput_tagAmount__2PhCV {
  color: #FFFFFF;
  font-family: 'Spartan';
  font-weight: 400;
  position: absolute;
  top: -12px;
  left: 20px;
  background-color: #505076;
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
}

.AmountInput_tagMax__3404H {
  color: #FF004C;
  font-family: 'Spartan';
  font-weight: 700;
  font-size: 12px;
  background-color: #FF004C21;
  padding: 5px;
  border-radius: 24px;
}

.AmountInput_input__3GPJI {
  flex: 1 1;
  border: none;
  margin-right: 10px;
}

.AmountInput_tokenButton__Q4X5M {
  font-family: 'Spartan';
  font-weight: 700;
  font-size: 12px;
  padding: 8px;
  width: 20%;
  border-radius: 9px;
}

@media (max-width: 448px) {
  .AmountInput_root__2_FMn {
    padding: 4px;
  }
  
  .AmountInput_tagAmount__2PhCV {
    top: -8px;
    left: 12px;
  }
}
.AppCallButton_root__1sQ91 {
  width: 100%;
  text-transform: none;
  border-radius: 15px 0;
  padding: 15px;
}
.SafeBody_root__M-8SI {
  border-radius: 16px;
  background-color: #393953;
  width: 90%;
  margin: 18px auto 72px auto;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
}

.SafeBody_container__3tcCr {
  padding: 60px 2% 30px 5%;
}

.SafeBody_descriptionBox__1R2YC {}

.SafeBody_tokenName__1JoUA {
  font-family: 'Spartan';
  font-weight: 700;
  color: #4DB2A8;
  line-height: 200%;
}

.SafeBody_descriptionText__1hCyR {
  font-family: 'Spartan';
  font-weight: 400;
  color: #DBDBDB;
  opacity: 0.8;
  line-height: 200%;
}

.SafeBody_appBox__LF11M {
  background-color: #505076;
  border-radius: 16px;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  height: 100%;
  padding-left: 8%;
  padding-right: 8%;
  display: flex;
  flex-direction: column;
}

.SafeBody_buttonGroup__322sg {
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
  background-color: #393953;
  border-radius: 16px;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  padding: 5px 5% 5px 5%;
}

.SafeBody_buttonAppSelect__1lGfy {
  font-family: 'Spartan';
  text-transform: none;
  font-size: 14px;
  letter-spacing: 0.02em;
  padding: 12px 0px 12px 0px;
  border-radius: 10px;
  flex: 1 1;
}

.SafeBody_buttonActive__2KbxF {
  font-weight: 700;
  color: #FFFFFF;
}

.SafeBody_buttonInactive__2XNmz {
  font-weight: 500;
  color: #E7E7FF;
}

.SafeBody_boxApp__JzwM- {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
}

.SafeBody_boxClaim__1oyt8 {}

.SafeBody_boxProtect__3hL2G {}

.SafeBody_boxWithdraw__2MhLQ {}

.SafeBody_paramArea__111Dj {
  flex: 1 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: right;
  grid-gap: 15px;
  gap: 15px;
}

.SafeBody_feeBox__1s6-k {
}

.SafeBody_paramName__3QBnb {
  font-family: 'Spartan';
  font-weight: 400;
  color: #FFFFFF;
  margin-bottom: 8px;
}

.SafeBody_paramValue__HxjIV {
  font-family: 'Spartan';
  font-weight: 500;
  color: #9D9D9D;
  background-color: #4E4E5F;
  border: 1px solid #2A2A40;
  border-radius: 10px;
  padding: 10px;
  width: 100px;
  margin-right: 0;
  margin-left: auto;
}

.SafeBody_delayList__1tW4Q {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  align-content: flex-end;
}

.SafeBody_delayValue__2lIlm {
  font-family: 'Spartan';
  font-weight: 500;
  color: #FFFFFF;
  border-radius: 10px;
  background-color: #3B3B59;
  border: 1px solid #2A2A40;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 65px;
  text-align: center;
  margin-right: 5px;
}

.SafeBody_delayValueSelected__pumzr {
  background-color: #5E5E83;
  border-color: #4DB2A8;
}

.SafeBody_tailTag__21125 {
  font-size: 10px;
  font-family: 'Spartan';
  font-weight: 700;
  color: #FFFFFF;
  padding-top: 5px;
}

.SafeBody_pendingAssets__3sZVc {
  color: #FFFFFF;
}

.SafeBody_pendingContainer__2B9h4 {
}

.SafeBody_pendingBox__aiYBb {
  padding: 20px;
}

.SafeBody_pendingTitle__2q76p {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.SafeBody_th__26bND {
  color: #DDDDDD;
}

@media (max-width: 1024px) {
  .SafeBody_appBox__LF11M {
    height: 400px;
  }

  .SafeBody_boxApp__JzwM- {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (max-width: 768px) {}

@media (max-width: 448px) {
  .SafeBody_appBox__LF11M {
    border-radius: 12px;
    padding-left: 2%;
    padding-right: 2%;
  }

  .SafeBody_buttonGroup__322sg {
    margin-top: 20px;
    border-radius: 12px;
    padding: 5px 0 5px 0;
  }

  .SafeBody_buttonAppSelect__1lGfy {
    font-size: 12px;
    border-radius: 6px;
    padding: 8px 0px 8px 0px;
  }

  .SafeBody_delayValue__2lIlm {
    width: 60px;
  }

  .SafeBody_pendingBox__aiYBb {
    padding: 10px;
  }

  .SafeBody_pendingTitle__2q76p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.Portfolio_root__36GzG {}

.Portfolio_safeList__23yqs {
  max-width: 700px;
  margin: 100px auto 100px auto;
}

.Portfolio_swapping__2Fbj8 {
  max-width: 700px;
  margin: 100px auto 100px auto;
}

.Portfolio_swappingTitle__3PZ-k {
  color: #FFFFFF;
  text-align: center;
  padding-bottom: 50px;
}

.Portfolio_tr__1D-6P {}

.Portfolio_th__3vecM {}

.Portfolio_td__2RQAW {}

.Portfolio_swapButton__1YoxF {
  text-transform: none;
  font-size: 18px;
}
.Safe_root__1nZGq {
  padding-top: 50px;
}

.Safe_topButtonGroup__3eRt1 {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 60px;
}

.Safe_topButton__3t4Ao {
  color: #FFFFFF;
  text-transform: none;
  margin-left: 8px;
  margin-right: 8px;
  padding: 12px 36px 12px 36px;
  font-family: 'Spartan';
  font-weight: 600;
  font-size: 14px;
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .Safe_root__1nZGq {
    padding-top: 15px;
  }

  .Safe_topButtonGroup__3eRt1 {
    margin-top: 15px;
  }
}

@media (max-width: 448px) {
  .Safe_topButton__3t4Ao {
    margin-left: 4px;
    margin-right: 4px;
    padding: 8px;
    font-size: 12px;
    border-radius: 6px;
  }
}
