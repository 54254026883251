.root {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 40px;
  height: 550px;
  position: relative;
  background-color: #3D4559;
  overflow: hidden;
}

.bgImg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.title {
  opacity: 0.5;
  padding-top: 75px;
  letter-spacing: -0.01em;
}

.header {
  position: relative;
  width: fit-content;
  margin: 25px auto;
}

.headerText {
  letter-spacing: -0.01em;
}

.headerCurve {
  position: absolute;
  right: 0;
  bottom: -10px;
  max-width: 80%;
}

.description {
  color: #D3D5DA;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 500;
  letter-spacing: -0.05em;
  margin-bottom: 20px;
}

.learnButton {
  background-color: #ffffff;
  color: #1E266D;
  text-transform: none;
  font-family: 'Spartan';
  width: 200px;
  height: 80px;
  border-radius: 15px;
  font-weight: 700;
  margin: 10px;
  font-size: 16px;
  padding: 10px;
}

@media (max-width: 1024px) {
  .root {
    width: 90%;
    border-radius: 30px;
    height: 500px;
  }
}

@media (max-width: 768px) {
  .root {
    width: 90%;
    border-radius: 30px;
    height: 450px;
  }

  .learnButton {
    width: 150px;
    margin: 5px;
    height: 60px;
    font-size: 14px;
  }
}

@media (max-width: 448px) {
  .root {
    border-radius: 20px;
  }

  .learnButton {
    width: 110px;
    height: 40px;
    border-radius: 8px;
    font-size: 12px;
    letter-spacing: -0.1em;
  }
}