.root {
  background: #393953;
  border-radius: 16px;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 100px;
  padding-bottom: 30px;
  padding-top: 100px;
}

.topStates {
  position: absolute;
  top: -75px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.topState {
  background-color: #393953;
  box-shadow: 14px 22px 52px -4px rgba(127, 127, 127, 0.13);
  border-radius: 40px;
  margin-left: 8px;
  margin-right: 8px;
  padding: 32px;
  display: flex;
}

.topStateValue {
  color: #FFFFFF;
  font-family: 'Spartan';
  font-weight: 700;
}

.topStateTitle {
  color: #FFFFFF;
  font-family: 'Spartan';
  font-weight: 400;
}

.leftColorPanel {
  position: relative;
  width: 65px;
  height: 65px;
  border-radius: 24px;
}

.leftColorPanel1 {
  background: linear-gradient(154.49deg, #FFE8AC 6.61%, #FFB700 89.72%);
  box-shadow: 0px 10px 22px -2px rgba(255, 183, 0, 0.3);
}

.leftColorPanel2 {
  background: linear-gradient(154.49deg, #313555 6.61%, #171A31 89.72%);
  box-shadow: 0px 10px 22px -2px rgba(23, 26, 49, 0.3);
}

.subtractIcon1 {
  position: absolute;
  left: 2px;
  top: -5px;
}

.subtractIcon2 {
  position: absolute;
  left: -15px;
  top: -5px;
}

.rightTextPanel {
  margin-left: 30px;
  height: 100%;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
}

.title {
  color: #FFFFFF;
  font-family: 'Spartan';
  font-weight: 700;
  text-align: center;
}

.searchBox {
  margin: 40px auto 40px auto;
  width: 95%;
  background: rgba(253, 253, 253, 0.88);
  backdrop-filter: blur(25px);
  border-radius: 14px;
  padding-top: 18px;
  padding-bottom: 18px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.searchIcon {
  margin-right: 10px;
}

.searchText {
  color: #1E266D;
  font-family: 'Manrope';
  font-weight: 400;
  opacity: 0.7;
}

.buttonGroup {
  align-items: left;
  display: flex;
  margin-left: 30px;
}

.button {
  box-shadow: 14px 22px 52px -12px rgba(127, 127, 127, 0.13);
  border-radius: 10px;
  text-transform: none;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 16px;
  padding: 16px 50px 16px 20px;
  margin-right: 10px;
}

.buttonAllNetworks {
  background: #6966FF;
  color: #F7F9FB;
}

.buttonAllTypes {
  background: #F7F9FB;
  color: #171A31;
}

@media (max-width: 768px) {
  .root {
    width: 95%;
    padding-top: 50px;
  }

  .topStates {
    top: -40px;
  }

  .topState {
    max-width: 250px;
    padding: 10px;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 20px;
  }

  .leftColorPanel {
    width: 40px;
    height: 40px;
    border-radius: 12px;
  }

  .subtractIcon1 {
    width: 15px;
    left: 1px;
    top: -3px;
  }
  
  .subtractIcon2 {
    width: 15px;
    left: -8px;
    top: -3px;
  }

  .rightTextPanel {
    margin-left: 10px;
  }

  .searchBox {
    margin: 20px auto 20px auto;
    border-radius: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .searchIcon {
    width: 15px;
  }

  .buttonGroup {
    margin-left: 10px;
  }

  .button {
    padding: 8px;
    border-radius: 5px;
    font-size: 14px;
  }
}